import React, { useRef, useState, useEffect } from "react";
import "./style.scss";
import { applySx, createRipple } from "../../utils";

const Button = ({ iconLight, iconDark, title, sx = {}, variant = "primary", action = null }) => {
    const customStyle = applySx(sx);
    const buttonRef = useRef(null);
    const [rippleStyle, setRippleStyle] = useState({});
    const [isRippling, setIsRippling] = useState(false);
    const [isDarkMode, setIsDarkMode] = useState(document.documentElement.classList.contains("dark"));

    useEffect(() => {
        const observer = new MutationObserver((mutations) => {
            mutations.forEach((mutation) => {
                if (mutation.attributeName === "class") {
                    const darkMode = document.documentElement.classList.contains("dark");
                    setIsDarkMode(darkMode);
                }
            });
        });

        observer.observe(document.documentElement, {
            attributes: true,
            attributeFilter: ["class"],
        });

        return () => {
            observer.disconnect();
        };
    }, []);

    const variantStyles = {
        primary: {
            '--button-title-color': isDarkMode ? 'var(--theme-white)' : 'var(--theme-white)', 
        },
        danger: {
            '--button-title-color': isDarkMode ? 'var(--theme-red)' : 'white', 
            '--button-bg-color': isDarkMode ? 'transparent' : 'var(--theme-red)',
        },
        pink: {
            '--button-title-color': isDarkMode ? 'var(--theme-pink)' : 'white', 
            '--button-bg-color': isDarkMode ? 'transparent' : 'var(--theme-pink)',
        },
        green: {
            '--button-title-color': isDarkMode ? 'var(--theme-green)' : 'white', 
            '--button-bg-color': isDarkMode ? 'transparent' : 'var(--theme-green)',
        },
        AED885: {
            '--button-title-color': isDarkMode ? 'var(--theme-green-2)' : 'white', 
            '--button-bg-color': isDarkMode ? 'transparent' : 'var(--theme-green-2)',
        }
    };

    const handleClick = (e) => {
        createRipple(e, buttonRef, setRippleStyle, setIsRippling);
        if (action) action();
    };

    return (
        <div
            ref={buttonRef}
            className="button"
            style={{
                ...customStyle,
                ...variantStyles[variant],
                backgroundColor: variant !== "primary" && !isDarkMode ? variantStyles[variant]['--button-bg-color'] : undefined,
                color: variant !== "primary" && !isDarkMode ? 'white' : undefined,
            }}
            onClick={handleClick}
        >
            {isRippling && <span className="ripple" style={rippleStyle} />}
            <div className="button_icon">
                <img src={isDarkMode ? iconDark : iconLight} alt="" className="button_icon_img" />
            </div>
            <span className="button_title">{title}</span>
        </div>
    );
};

export default Button;
