import React from "react";
import "./style.scss";
import Button from "../../components/Button";
import Localized from "../../Localized";
import { useAppDispatch } from "../../app/hooks";
import { useNavigate } from "react-router-dom";
import { initUtils } from '@telegram-apps/sdk';
import {
    changeStep,
    pushRoute,
    setAnimationDirection,
} from "../../redux/slices/windowSlice";

import officeBagDark from "../../img/icons/dark/office-bag.svg";
import officeBagLight from "../../img/icons/light/office-bag.svg";

import addDark from "../../img/icons/dark/add.svg";
import addLight from "../../img/icons/light/add.svg";

import creditCardDark from "../../img/icons/dark/credit-card.svg";
import creditCardLight from "../../img/icons/light/credit-card.svg";

import supportIconDark from "../../img/icons/dark/support.svg";
import supportIconLight from "../../img/icons/light/support.svg";

const MainScreen = () => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const utils = initUtils();

    const openMySubscriptions = () => {
        dispatch(setAnimationDirection("backward"));
        dispatch(changeStep("my_subscriptions"));
        dispatch(
            pushRoute({ step: "my_subscriptions", path: "/my", swipe: "forward" })
        );
        navigate("/my"); // Переход к маршруту /m
    };

    const openAvailableSubscriptions = () => {
        dispatch(setAnimationDirection("backward"));
        dispatch(changeStep("available_subscriptions"));
        dispatch(
            pushRoute({
                step: "available_subscriptions",
                path: "/available_subscriptions",
                swipe: "forward",
            })
        );
        navigate("/available_subscriptions"); // Переход к маршруту /m
    };

    const openPaymentSettings = () => {
        dispatch(setAnimationDirection("backward"));
        dispatch(changeStep("payment_settings"));
        dispatch(
            pushRoute({
                step: "payment_settings",
                path: "/payment_settings",
                swipe: "forward",
            })
        );
        navigate("/payment_settings"); // Переход к маршруту /m
    };

    return (
        <div className="main-page">
            <div className="main-page_container">
                <div className="main-page_wrapper">
                    <span className="main-page_welcometext">
                        🌸 Коллега, приветствуем тебя в Центре профессиональной
                        подготовки психологов «Терапия в лицах»!
                    </span>
                    <Button
                        title={Localized.MySubscriptions}
                        iconLight={officeBagLight}
                        iconDark={officeBagDark}
                        action={openMySubscriptions}
                    />
                    <Button
                        title={Localized.AvailableSubscription}
                        iconLight={addLight}
                        iconDark={addDark}
                        action={openAvailableSubscriptions}
                    />
                    <Button
                        title={Localized.PaymentSettngs}
                        iconLight={creditCardLight}
                        iconDark={creditCardDark}
                        action={openPaymentSettings}
                    />
                    <Button
                        title={Localized.ManagerContact}
                        iconLight={supportIconLight}
                        iconDark={supportIconDark}
                        action={() => {
                            utils.openTelegramLink('https://t.me/RinaZG');
                        }}
                    />
                </div>
            </div>
        </div>
    );
};

export default MainScreen;
