import React, { useState } from "react";
import { CSSTransition } from "react-transition-group";
import "../MySubscriptions/style.scss";
import { useAppDispatch } from "../../app/hooks";
import { useNavigate } from "react-router-dom";
import { changeStep, pushRoute, setAnimationDirection } from "../../redux/slices/windowSlice";

import SubscriptionButton from "../../components/SubscriptionButton";

const MySubscriptions = () => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const openMySubscriptions = () => {
        dispatch(setAnimationDirection("backward"));
        dispatch(changeStep("subscription_new"));
        dispatch(pushRoute({ step: 'subscription_new', path: '/subscription_new', swipe: 'forward' }));
        navigate("/subscription_new"); // Переход к маршруту /my
    };

    return (
        <div className="my-subscriptions">
            <div className="my-subscriptions_container">
                <div className="my-subscriptions_wrapper">
                    
                    <SubscriptionButton
                        title="Вебинар «Все о знакомствах и свиданиях Online и не только» Ю.Сушкова и Ю.Дицевич"
                        dateType="takeplace"
                        dateValue="08.09.2024"
                        price="90 USD"
                        image=""
                        action={openMySubscriptions}
                    />
                    
                </div>
            </div>
        </div>
    );
};

export default MySubscriptions;
