import React, { useState, useRef, useEffect } from "react";
import { initHapticFeedback } from '@telegram-apps/sdk';
import { Bounce, toast } from "react-toastify";
import "./style.scss";

const SliderButton = ({ sx = {}, variant = "primary" }) => {
    const hapticFeedback = initHapticFeedback();

    const [sliderValue, setSliderValue] = useState(0);
    const [isSliding, setIsSliding] = useState(false);
    const [isPaymentProcessing, setIsPaymentProcessing] = useState(false);
    const [isPaymentFailed, setIsPaymentFailed] = useState(false);
    const [showFailureMessage, setShowFailureMessage] = useState(false); // Состояние для отображения сообщения
    const intervalRef = useRef(null);
    const sliderElementRef = useRef(null);
    const containerRef = useRef(null);

    useEffect(() => {
        return () => clearInterval(intervalRef.current);
    }, []);

    const getHapticIntensity = (value) => {
        if (value <= 40) return 'light';
        if (value <= 80) return 'soft';
        if (value <= 120) return 'medium';
        if (value <= 160) return 'rigid';
        return 'heavy';
    };

    const handleSliderChange = (event) => {
        if (isPaymentProcessing || isPaymentFailed) return;

        const value = event.target.value;
        setSliderValue(value);
    
        const percentage = (value / 200) * 100;
        const radius = Math.min(8, (value / 200) * 8);
        sliderElementRef.current.style.setProperty('--slider-progress', `${percentage}%`);
        sliderElementRef.current.style.setProperty('--slider-radius', `${radius}px`);
    
        const intensity = getHapticIntensity(sliderValue);
        hapticFeedback.impactOccurred(intensity);
    };
    
    const handleMouseDown = () => {
        if (isPaymentProcessing || isPaymentFailed) return;

        setIsSliding(true);
        
        if (!intervalRef.current) {
            intervalRef.current = setInterval(() => {
                const intensity = getHapticIntensity(sliderValue);
                hapticFeedback.impactOccurred(intensity);
            }, 100);
        }
    };

    const handleMouseUp = () => {
        if (isPaymentProcessing || isPaymentFailed) return;

        setIsSliding(false);
        clearInterval(intervalRef.current);
        intervalRef.current = null;

        if (sliderValue >= 200) {
            handlePaymentProcessing(); 
        } else {
            setSliderValue((prevValue) => {
                const step = prevValue / 20;
                let currentValue = prevValue;

                const interval = setInterval(() => {
                    currentValue -= step;
                    const percentage = (currentValue / 200) * 100;
                    const radius = Math.min(8, (currentValue / 200) * 8);

                    if (sliderElementRef.current) {
                        sliderElementRef.current.style.setProperty('--slider-progress', `${percentage}%`);
                        sliderElementRef.current.style.setProperty('--slider-radius', `${radius}px`);
                    }

                    if (currentValue <= 0) {
                        clearInterval(interval);
                        setSliderValue(0);
                        if (sliderElementRef.current) {
                            sliderElementRef.current.style.setProperty('--slider-progress', `0%`);
                            sliderElementRef.current.style.setProperty('--slider-radius', `0px`);
                        }
                    } else {
                        setSliderValue(currentValue);
                    }
                }, 15);

                return prevValue;
            });
        }
    };

    const handleTouchMove = (event) => {
        if (isPaymentProcessing || isPaymentFailed) return;

        const touch = event.touches[0];
        const rect = sliderElementRef.current.getBoundingClientRect();
        const offsetX = touch.clientX - rect.left;
        const percentage = Math.min(Math.max(offsetX / rect.width, 0), 1);
        const value = Math.round(percentage * 200);
        setSliderValue(value);
        const intensity = getHapticIntensity(sliderValue);
        hapticFeedback.impactOccurred(intensity);
    };

    const handlePaymentProcessing = () => {
        setIsPaymentProcessing(true);
        hapticFeedback.notificationOccurred('warning');
        setSliderValue(200);
        sliderElementRef.current.style.setProperty('--slider-progress', `100%`);
        sliderElementRef.current.style.setProperty('--slider-radius', `8px`);

        sliderElementRef.current.classList.add('payment-processing');

        // Симулируем неудачную оплату через 8 секунд
        setTimeout(() => {
            setIsPaymentProcessing(false);
            handlePaymentFailure(); // Вызываем обработчик неудачной оплаты
        }, 8000);
    };

    const handlePaymentFailure = () => {
        hapticFeedback.notificationOccurred('error');
        toast.error('Оплата не прошла. Обратитесь в банк или используйте другую карту.', {
            position: "top-right",
            autoClose: 4000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: false,
            progress: undefined,
            theme: "colored",
            transition: Bounce,
            });
        setIsPaymentFailed(true);
        setShowFailureMessage(true); // Показываем сообщение "Не оплатилось"
        if (containerRef.current) {
            containerRef.current.style.setProperty('--theme-slider-item', '#BD293D'); // Устанавливаем красный цвет
        }

        setTimeout(() => {
            // Возвращаем ползунок и фон к исходным цветам
            setSliderValue((prevValue) => {
                const step = prevValue / 20;
                let currentValue = prevValue;

                const interval = setInterval(() => {
                    currentValue -= step;
                    const percentage = (currentValue / 200) * 100;
                    const radius = Math.min(8, (currentValue / 200) * 8);

                    if (sliderElementRef.current) {
                        sliderElementRef.current.style.setProperty('--slider-progress', `${percentage}%`);
                        sliderElementRef.current.style.setProperty('--slider-radius', `${radius}px`);
                    }

                    if (currentValue <= 0) {
                        clearInterval(interval);
                        setSliderValue(0);
                        if (sliderElementRef.current) {
                            sliderElementRef.current.style.setProperty('--slider-progress', `0%`);
                            sliderElementRef.current.style.setProperty('--slider-radius', `0px`);
                        }
                        setIsPaymentFailed(false);
                        setShowFailureMessage(false); // Скрываем сообщение "Не оплатилось"
                        if (containerRef.current) {
                            containerRef.current.style.setProperty('--theme-slider-item', ''); // Возвращаем к исходному цвету
                        }
                    } else {
                        setSliderValue(currentValue);
                    }
                }, 15);

                return prevValue;
            });
        }, 4000); // Оставляем красный фон и сообщение на 4 секунды
    };

    const customStyle = sx;

    const variantStyles = {
        primary: {
            '--button-title-color': 'var(--theme-white)', 
        },
        danger: {
            '--button-title-color': 'var(--theme-red)', 
        },
        pink: {
            '--button-title-color': 'var(--theme-pink)', 
        },
        green: {
            '--button-title-color': 'var(--theme-green)', 
        }
    };

    return (
        <div 
            ref={containerRef} // Ссылка на контейнер для управления CSS переменной
            className={`slider-button ${isPaymentFailed ? 'payment-failed' : ''}`}
            style={{ ...customStyle, ...variantStyles[variant] }}
        >
            <span className={`slider-button_title ${isSliding || isPaymentProcessing || isPaymentFailed ? 'hidden' : ''}`}>Оплатить картой •••• 9078</span>
            <span className={`slider-button_title second ${isSliding || isPaymentProcessing || isPaymentFailed ? 'hidden' : ''}`}>Проведите для оплаты</span>
            <span className={`slider-button_title ${isPaymentProcessing ? '' : 'hidden'}`}>Идет оплата...</span>
            <span className={`slider-button_title second ${isPaymentProcessing ? '' : 'hidden'}`}>Это может занять время</span>
            {showFailureMessage && (
                <span className="slider-button_title failure-message">Не оплатилось</span> // Сообщение "Не оплатилось"
            )}
            <input
                ref={sliderElementRef}
                type="range"
                min="0"
                max="200"
                value={sliderValue}
                onChange={handleSliderChange}
                onMouseDown={handleMouseDown}
                onMouseUp={handleMouseUp}
                onTouchStart={handleMouseDown}
                onTouchMove={handleTouchMove}
                onTouchEnd={handleMouseUp}
                disabled={isPaymentProcessing || isPaymentFailed}
                className={`slider-button_slider ${isSliding || isPaymentProcessing || isPaymentFailed ? 'no-shine' : ''}`}
            />
        </div>
    );
};

export default SliderButton;
