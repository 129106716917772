import React from "react";
import './style.scss';

import { useAppDispatch } from "../../app/hooks";
import { useNavigate } from "react-router-dom";

import {
    changeStep,
    pushRoute,
    setAnimationDirection,
} from "../../redux/slices/windowSlice";

import paymentSettingsIconCard from '../../img/icons/payment-settings-card.svg';
import ukassaLogo from '../../img/icons/ukassa.svg';
import bepaidLogo from '../../img/icons/bepaid.svg';
import stripeLogo from '../../img/icons/stripe.svg';
import Button from "../../components/Button";
import Localized from "../../Localized";

import creditCardDark from "../../img/icons/dark/credit-card.svg";
import creditCardLight from "../../img/icons/light/credit-card.svg";

import dangerCreditCardDark from "../../img/icons/dark/remove-credit-card.svg";


const PaymentSettngs = () => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const openAddNewCard = () => {
        dispatch(setAnimationDirection("backward"));
        dispatch(changeStep("add_new_card"));
        dispatch(
            pushRoute({
                step: "add_new_card",
                path: "/add_new_card",
                swipe: "forward",
            })
        );
        navigate("/add_new_card"); // Переход к маршруту /m
    };
    return (
        <div className="payment-settings">
            <div className="payment-settings_container">
                <div className="payment-settings_wrapper">
                    <div className="payment-settings_card-information">
                        <div className="payment-settings_card-information_details">
                            <div className="payment-settings_card-information_details_icon">
                                <img src={paymentSettingsIconCard} alt="Card" className="payment-settings_card-information_details_icon_img" />
                            </div>
                            <span className="payment-settings_card-information_details_title">
                                Visa PKO (PL)
                            </span>
                            <span className="payment-settings_card-information_details_number">
                                •••• •••• •••• 9078
                            </span>
                        </div>
                        <div className="payment-settings_card-information_provider">
                            <img src={stripeLogo} alt="Ukassa" className="payment-settings_card-information_provider_img" />
                        </div>
                    </div>
                    <Button
                        title="Привязать новую карту"
                        iconLight={creditCardLight}
                        iconDark={creditCardDark}
                        action={openAddNewCard}
                        sx={{mt: "50px"}}
                    />
                    <Button
                        title="Удалить карту из системы"
                        iconLight={creditCardDark}
                        iconDark={dangerCreditCardDark}
                        variant="danger"
                        // action={}
                    />
                </div>
            </div>
        </div>
    );
};

export default PaymentSettngs;
