import React, { useCallback } from "react";
import { loadStripe } from '@stripe/stripe-js';
import {
  EmbeddedCheckoutProvider,
  EmbeddedCheckout
} from '@stripe/react-stripe-js';

// Load Stripe outside of a component's render to avoid recreating the Stripe object on every render.
const stripePromise = loadStripe("pk_test_51O5Bi5JaTdslYubfFEOCUCIZGgN6xYNuR0DB35f0frpl5YAIWD630ldsTsdzefB4aHTt123OgYBQN5Mqe1RvPhyx002lxSGLax");

const CheckoutForm = () => {

  // Options for Embedded Checkout
  const options = { clientSecret: "cs_test_c1v8fqngf5dpJzXFFSnlCtT8EDt5EXe5PsNvz3hhDJ3m0OCKgamSluzery_secret_fidwbEhqYWAnPydgaGdgYWFgYScpJ2lkfGpwcVF8dWAnPyd2bGtiaWBaZmppcGhrJyknd2BhbHdgZnFKa0ZqaHVpYHFsamsnPydrYHNgdycpJ2dkZm5id2pwa2FGamlqdyc%2FJyZhPGZkY2AneCUl" };

  return (
      <EmbeddedCheckoutProvider
        stripe={stripePromise}
        options={options}
      >
        <EmbeddedCheckout />
      </EmbeddedCheckoutProvider>
  );
};

export default CheckoutForm;
