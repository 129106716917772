import React, { useState } from "react";
import { CSSTransition } from "react-transition-group";
import "./style.scss";
import Localized from "../../Localized";
import { useAppDispatch } from "../../app/hooks";
import { useNavigate } from "react-router-dom";
import { changeStep, pushRoute, setAnimationDirection } from "../../redux/slices/windowSlice";

import SubscriptionButton from "../../components/SubscriptionButton";

const AvailableSubscriptions = () => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const openMySubscriptions = () => {
        dispatch(setAnimationDirection("backward"));
        dispatch(changeStep("subscription_info"));
        dispatch(pushRoute({ step: 'subscription_info', path: '/subscription_info', swipe: 'forward' }));
        navigate("/subscription_info"); // Переход к маршруту /my
    };

    return (
        <div className="my-subscriptions">
            <div className="my-subscriptions_container">
                <div className="my-subscriptions_wrapper">
                    <span className="my-subscriptions_text">{Localized.ActiveSubscription}</span>
                    <SubscriptionButton
                        title="Вебинар «Все о знакомствах и свиданиях Online и не только» Ю.Сушкова и Ю.Дицевич"
                        dateType="takeplace"
                        dateValue="08.09.2024"
                        price="90 USD"
                        image=""
                        action={openMySubscriptions}
                    />
                    <SubscriptionButton
                        title="ГЕШТАЛЬТ В ЛИЦАХ"
                        dateType="renew"
                        dateValue="08.09.2024"
                        price="12,99 USD"
                        image=""
                    />
                    <SubscriptionButton
                        title="Клуб Терапевтов"
                        dateType="renew"
                        dateValue="08.09.2024"
                        price="Бесплатно"
                        image=""
                    />
                    <SubscriptionButton
                        title="КПТ В ЛИЦАХ"
                        dateType="expire"
                        dateValue="08.09.2024"
                        price="20 USD"
                        image=""
                    />
                    <span className="my-subscriptions_text" style={{marginTop: "25px"}}>{Localized.ExpiredSubscription}</span>
                    <SubscriptionButton
                        title="Большая игра “Королевство”"
                        dateType="expired"
                        dateValue="08.09.2024"
                        price=""
                        image=""
                    />
                </div>
            </div>
        </div>
    );
};

export default AvailableSubscriptions;
