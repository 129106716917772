import React, { useState } from "react";
import "./style.scss";
import { CircularProgress, Typography } from "@mui/material";
import Button from "../../components/Button";
import creditCardDark from "../../img/icons/dark/credit-card.svg";
import creditCardLight from "../../img/icons/light/credit-card.svg";
import CheckoutForm from "./CheckoutForm";

const AddNewPaymentCardChoise = () => {
    const [showStripe, setShowStripe] = useState(false);
    const [showLoading, setShowLoading] = useState(false);
    const [showBePaidLoading, setShowBePaidLoading] = useState(false);

    const handleOpenStripe = () => {
        setShowLoading(true);
        setShowStripe(true);
        setTimeout(() => {
            setShowLoading(false);
        }, 10000);
    };

    const handleCloseStripe = () => {
        setShowStripe(false);
    };

    const handleOpenBePaid = () => {
        setShowBePaidLoading(true);
        openBePaidWidget();
        setTimeout(() => {
            setShowBePaidLoading(false);
        }, 10000);
    };

    const openBePaidWidget = () => {
        const params = {
            checkout_url: "https://checkout.bepaid.by",
            token: "535f88e4bf5d57ad3b9247f5f8960392a40acbff6a05d4ffd8404329f78fde9c",
            fromWebview: true,
            closeWidget: function (status) {
                console.debug("close widget callback", status);
                if (status === "successful") {
                    console.log("Payment was successful");
                } else if (status === "failed") {
                    console.log("Payment failed");
                } else if (status === "pending") {
                    console.log("Payment is pending");
                } else if (status === "redirected") {
                    console.log("User was redirected to an external payment system");
                } else if (status === "error") {
                    console.log("There was an error with the payment");
                } else if (status === null) {
                    console.log("Widget was closed without starting the payment");
                }
            },
        };
        //eslint-disable-next-line @typescript-eslint/ban-ts-comment
        //@ts-ignore
        new window.BeGateway(params).createWidget();
    };

    return (
        <div className="payment-settings">
            <div className="payment-settings_container">
                {!showLoading && !showStripe && !showBePaidLoading && (
                    <div className="payment-settings_wrapper">
                        <Button
                            title="Карта банка РБ"
                            iconLight={creditCardLight}
                            iconDark={creditCardDark}
                            action={handleOpenBePaid}
                        />
                        <Button
                            title="Карта из зарубежа"
                            iconLight={creditCardLight}
                            iconDark={creditCardDark}
                            action={handleOpenStripe}
                        />
                    </div>
                )}
                {(showLoading || showBePaidLoading) && (
                    <div className="loading-message">
                        <CircularProgress
                            variant="indeterminate"
                            size={25}
                            thickness={4}
                            sx={{
                                color: (theme) =>
                                    theme.palette.mode === 'light' ? theme.palette.grey[800] : theme.palette.grey[100],
                            }}
                        />
                        <span className="loading-message_text">Сейчас откроется окно ввода карты</span>
                        
                    </div>
                )}
            </div>
            {showStripe && (
                <div className="stripe-overlay">
                    <div className="stripe-modal">
                        <button className="close-button" onClick={handleCloseStripe}>
                            &times;
                        </button>
                        <CheckoutForm />
                    </div>
                </div>
            )}
        </div>
    );
};

export default AddNewPaymentCardChoise;
