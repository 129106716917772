import LocalizedStrings from 'react-localization';

let Localized = new LocalizedStrings({
    ru: {
      MySubscriptions: "Мои подписки",
      AvailableSubscription: "Доступные к оформлению",
      PaymentSettngs: "Настройки оплаты",
      ManagerContact: "Связаться с менеджером",

      ActiveSubscription: "Активные",
      ExpiredSubscription: "Истёкшие",

      WillRenew: "Обновится",
      WillExpire: "Истекает",

      Takeplace: "Состоится",

      GoToChat: "Перейти в группу",

      CancelSubscription: "Отменить подписку",
      RenewSubscription: "Возобновить подписку",

      AddNewCard: "Привязать новую карту",
      RemoveCard: "Удалить карту из системы",

      AddNewCardBelarus: "Карта банка РБ",
      AddNewCardRussia: "Карта банка РФ",
      AddNewCardOther: "Карта из зарубежа",
    },
    pl: {
      MySubscriptions: "Moje subskrypcje",
      AvailableSubscription: "Dostępne do subskrypcji",
      PaymentSettngs: "Ustawienia płatności",
      ManagerContact: "Skontaktuj się z menedżerem",

      ActiveSubscription: "Aktywne",
      ExpiredSubscription: "Wygasłe",

      WillRenew: "Odnowi się",
      WillExpire: "Wygasa",

      Takeplace: "Odbędzie się",

      GoToChat: "Przejdź do grupy",

      CancelSubscription: "Anuluj subskrypcję",
      RenewSubscription: "Odnów subskrypcję",

      AddNewCard: "Dodaj nową kartę",
      RemoveCard: "Usuń kartę z systemu",

      AddNewCardBelarus: "Karta banku Białorusi",
      AddNewCardRussia: "Karta banku Rosji",
      AddNewCardOther: "Karta zagraniczna",
    },
    en: {
      MySubscriptions: "My Subscriptions",
      AvailableSubscription: "Available to Subscribe",
      PaymentSettngs: "Payment Settings",
      ManagerContact: "Contact Manager",

      ActiveSubscription: "Active",
      ExpiredSubscription: "Expired",

      WillRenew: "Will Renew",
      WillExpire: "Expires",

      Takeplace: "Will Take Place",

      GoToChat: "Go to Group",

      CancelSubscription: "Cancel Subscription",
      RenewSubscription: "Renew Subscription",

      AddNewCard: "Add New Card",
      RemoveCard: "Remove Card from System",

      AddNewCardBelarus: "Belarus Bank Card",
      AddNewCardRussia: "Russia Bank Card",
      AddNewCardOther: "Foreign Bank Card",
    },
    be: {
      MySubscriptions: "Мае падпіскі",
      AvailableSubscription: "Даступныя да афармлення",
      PaymentSettngs: "Налады аплаты",
      ManagerContact: "Звязацца з мэнэджэрам",

      ActiveSubscription: "Актыўныя",
      ExpiredSubscription: "Састарэлыя",

      WillRenew: "Абновіцца",
      WillExpire: "Сканае",

      Takeplace: "Адбудзецца",

      GoToChat: "Перайсці ў групу",

      CancelSubscription: "Адмяніць падпіску",
      RenewSubscription: "Аднавіць падпіску",

      AddNewCard: "Дадаць новую карту",
      RemoveCard: "Выдаліць карту з сістэмы",

      AddNewCardBelarus: "Карта банка РБ",
      AddNewCardRussia: "Карта банка РФ",
      AddNewCardOther: "Карта з-за мяжы",
    },
    uk: {
      MySubscriptions: "Мої підписки",
      AvailableSubscription: "Доступні для оформлення",
      PaymentSettngs: "Налаштування оплати",
      ManagerContact: "Зв'язатися з менеджером",

      ActiveSubscription: "Активні",
      ExpiredSubscription: "Закінчилися",

      WillRenew: "Оновиться",
      WillExpire: "Закінчується",

      Takeplace: "Відбудеться",

      GoToChat: "Перейти до групи",

      CancelSubscription: "Скасувати підписку",
      RenewSubscription: "Відновити підписку",

      AddNewCard: "Прив'язати нову карту",
      RemoveCard: "Видалити карту з системи",

      AddNewCardBelarus: "Карта банку Білорусі",
      AddNewCardRussia: "Карта банку Росії",
      AddNewCardOther: "Іноземна карта",
    },
    de: {
      MySubscriptions: "Meine Abonnements",
      AvailableSubscription: "Verfügbar zum Abonnieren",
      PaymentSettngs: "Zahlungseinstellungen",
      ManagerContact: "Kontaktieren Sie den Manager",

      ActiveSubscription: "Aktiv",
      ExpiredSubscription: "Abgelaufen",

      WillRenew: "Wird erneuert",
      WillExpire: "Läuft ab",

      Takeplace: "Wird stattfinden",

      GoToChat: "Zur Gruppe gehen",

      CancelSubscription: "Abonnement kündigen",
      RenewSubscription: "Abonnement erneuern",

      AddNewCard: "Neue Karte hinzufügen",
      RemoveCard: "Karte aus dem System entfernen",

      AddNewCardBelarus: "Belarus Bankkarte",
      AddNewCardRussia: "Russland Bankkarte",
      AddNewCardOther: "Ausländische Bankkarte",
    },
    lv: {
      MySubscriptions: "Mani abonementi",
      AvailableSubscription: "Pieejams abonēšanai",
      PaymentSettngs: "Maksājumu iestatījumi",
      ManagerContact: "Sazinieties ar vadītāju",

      ActiveSubscription: "Aktīvs",
      ExpiredSubscription: "Beidzies",

      WillRenew: "Atjaunosies",
      WillExpire: "Beidzas",

      Takeplace: "Notiks",

      GoToChat: "Iet uz grupu",

      CancelSubscription: "Atcelt abonementu",
      RenewSubscription: "Atjaunot abonementu",

      AddNewCard: "Pievienot jaunu karti",
      RemoveCard: "Noņemt karti no sistēmas",

      AddNewCardBelarus: "Baltkrievijas bankas karte",
      AddNewCardRussia: "Krievijas bankas karte",
      AddNewCardOther: "Ārzemju banka karte",
    },
    lt: {
      MySubscriptions: "Mano prenumeratos",
      AvailableSubscription: "Galima užsiprenumeruoti",
      PaymentSettngs: "Mokėjimo nustatymai",
      ManagerContact: "Susisiekti su vadybininku",

      ActiveSubscription: "Aktyvūs",
      ExpiredSubscription: "Pasibaigę",

      WillRenew: "Bus atnaujinta",
      WillExpire: "Pasibaigia",

      Takeplace: "Įvyks",

      GoToChat: "Eiti į grupę",

      CancelSubscription: "Atšaukti prenumeratą",
      RenewSubscription: "Atnaujinti prenumeratą",

      AddNewCard: "Pridėti naują kortelę",
      RemoveCard: "Pašalinti kortelę iš sistemos",

      AddNewCardBelarus: "Baltarusijos banko kortelė",
      AddNewCardRussia: "Rusijos banko kortelė",
      AddNewCardOther: "Užsienio banko kortelė",
    },
});

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
const { WebApp } = window.Telegram;
var lang = WebApp.initDataUnsafe.user?.language_code;

Localized.setLanguage('ru');
if (lang === 'pl' || lang === 'en' || lang === 'be' || lang === 'uk' || lang === 'de' || lang === 'lv' || lang === 'lt') {
  Localized.setLanguage(lang);
}

export default Localized;
