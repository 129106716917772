import React, { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "./app/hooks";
import { RootState } from "./app/store";
import { useNavigate } from "react-router-dom";
import { popRoute, changeStep, setAnimationDirection } from "./redux/slices/windowSlice";
import { mockTelegramEnv, parseInitData, initInitData } from '@telegram-apps/sdk';
import { initBackButton } from '@telegram-apps/sdk';
import { retrieveLaunchParams } from '@telegram-apps/sdk';



const App: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const initDataRaw = new URLSearchParams([
        ['user', JSON.stringify({
          id: 99281932,
          first_name: 'Andrew',
          last_name: 'Rogue',
          username: 'rogue',
          language_code: 'en',
          is_premium: true,
          allows_write_to_pm: true,
        })],
        ['hash', '89d6079ad6762351f38c6dbbc41bb53048019256a9443988af7a48bcad16ba31'],
        ['auth_date', '1716922846'],
        ['start_param', 'debug'],
        ['chat_type', 'sender'],
        ['chat_instance', '8428209589180549439'],
      ]).toString();
      
      mockTelegramEnv({
        themeParams: {
          accentTextColor: '#6ab2f2',
          bgColor: '#17212b',
          buttonColor: '#5288c1',
          buttonTextColor: '#ffffff',
          destructiveTextColor: '#ec3942',
          headerBgColor: '#17212b',
          hintColor: '#708499',
          linkColor: '#6ab3f3',
          secondaryBgColor: '#232e3c',
          sectionBgColor: '#17212b',
          sectionHeaderTextColor: '#6ab3f3',
          subtitleTextColor: '#708499',
          textColor: '#f5f5f5',
        },
        initData: parseInitData(initDataRaw),
        initDataRaw,
        version: '7.2',
        platform: 'tdesktop',
      });
      
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const [backButton] = initBackButton();
    // const { initDataRaw, initData } = retrieveLaunchParams();

    const { routeStack } = useAppSelector(
        (state: RootState) => state.windowSlice
    );


      
    useEffect(() => {
        const handleBackButtonClick = () => {
            if (routeStack.length > 0) {
                const previousRoute = routeStack[routeStack.length - 1];

                if (previousRoute && previousRoute.path && previousRoute.step) {
                    dispatch(setAnimationDirection(previousRoute.swipe));
                    dispatch(popRoute());
                    dispatch(changeStep(previousRoute.step)); // Обновляем состояние шага
                    navigate(previousRoute.path); // Перемещаемся на предыдущий маршрут
                } else {
                    console.error("Invalid previousRoute:", previousRoute);
                }

                console.log("Route Stack after pop:", routeStack);
            }
        };

        backButton.show();

        backButton.on('click', handleBackButtonClick);
        if (routeStack.length === 1) {
            backButton.hide();
        } else {
            backButton.show();
        }

        return () => {
            backButton.off('click', handleBackButtonClick);
        };
    }, [routeStack, dispatch, navigate]);

    return <div>{children}</div>;
};

export default App;
