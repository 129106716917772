import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { api } from "../api";
import { WindowInfo } from "../types/window";

const initialState: WindowInfo = {
    stepCurrent: "main",
    loaderWindow: true,
    loaderButton: false,
    checkoutMsg: "",
    globalMsg: "",
    headerCutsom: "",
    isTestMode: false,
    animationDirection: "forward",
    serverUnavailable: false,
    routeStack: [{
        path: "/",
        step: "main",
        swipe: "forward"
    }],
};

const windowInfo = createSlice({
    name: "window",
    initialState,
    reducers: {
        changeStep(state, action: PayloadAction<string>) {
          console.log("changeStep " + action.payload);
          state.stepCurrent = action.payload;
        },

        setAnimationDirection(state, action: PayloadAction<string>) {
            state.animationDirection = action.payload;
        },

        isMainLoader(state, action: PayloadAction<boolean>) {
            state.loaderWindow = action.payload;
        },

        isButtonLoader(state, action: PayloadAction<boolean>) {
            state.loaderButton = action.payload;
        },
        setCheckoutMsg(state, action: PayloadAction<string>) {
            state.checkoutMsg = action.payload;
        },
        setGlobalMsg(state, action: PayloadAction<string>) {
            state.globalMsg = action.payload;
        },

        pushRoute (state, action)  {
            state.routeStack.push(action.payload);
        },
        popRoute (state) {
            console.log("pop");
            
            state.routeStack.pop();
        },
        resetRouteStack (state) {
            state.routeStack = [];
        },
    },
    extraReducers: (builder) => {
        builder

            // .addMatcher(
            //   api.endpoints.updateProducts.matchRejected,
            //   (state, { payload }) => {
            //       state.loaderButton = false;
            //   }
            // )
            .addMatcher(
                api.endpoints.updateProducts.matchRejected,
                (state, action) => {}
            )
            .addMatcher(
                api.endpoints.getPaymentMethods.matchFulfilled,
                (state, { payload }) => {
                    state.loaderButton = false;
                }
            );
    },
});

export const {
    changeStep,
    isMainLoader,
    isButtonLoader,
    setCheckoutMsg,
    setGlobalMsg,
    setAnimationDirection,
    pushRoute,
    popRoute,
    resetRouteStack,
} = windowInfo.actions;
export default windowInfo.reducer;
