import React from "react";
import ReactDOM from "react-dom/client";
import reportWebVitals from "./reportWebVitals";
import {
    BrowserRouter as Router,
    Routes,
    Route,
    useLocation,
} from "react-router-dom";
import { store } from "./app/store";
import { Provider } from "react-redux";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import { useAppSelector } from "./app/hooks";
import { RootState } from "./app/store";

import "./css/normalize.css";
import "./style.scss";
import Header from "./components/Header";
import MySubscriptions from "./pages/MySubscriptions";
import MainScreen from "./pages/MainScreen";
import SubscriptionInfo from "./pages/SubscriptionInfo";
import App from "./App";
import AvailableSubscriptions from "./pages/AvailableSubscriptions";
import SubscriptionNew from "./pages/SubscriptionNew";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import StatusWrapper from "./components/StatusWrapper";
import PaymentSettngs from "./pages/PaymentSettings";
import AddNewPaymentCardChoise from "./pages/AddNewPaymentCardChoise";

const AnimatedRoutes = () => {
    const location = useLocation(); // Получаем текущее местоположение
    const { animationDirection } = useAppSelector(
        (state: RootState) => state.windowSlice
    );

    return (
        <TransitionGroup>
            <CSSTransition
                key={location.pathname} // Используем путь как ключ для анимации
                classNames={`page-${animationDirection}`} // Имя класса для анимации
                timeout={500} // Время анимации
            >
                <Routes location={location}>
                    <Route path="/" element={<MainScreen />} />
                    <Route path="/my" element={<MySubscriptions />} />
                    <Route path="/available_subscriptions" element={<AvailableSubscriptions />} />
                    <Route
                        path="/subscription_info"
                        element={<SubscriptionInfo />}
                    />
                    <Route
                        path="/subscription_new"
                        element={<SubscriptionNew />}
                    />
                    <Route
                        path="/payment_settings"
                        element={<PaymentSettngs />}
                    />
                    <Route
                        path="/add_new_card"
                        element={<AddNewPaymentCardChoise />}
                    />
                </Routes>
            </CSSTransition>
        </TransitionGroup>
    );
};

const root = ReactDOM.createRoot(
    document.getElementById("root") as HTMLElement
);
root.render(
    <Provider store={store}>
        <React.StrictMode>
            <Router>
                <App>
                    <Header />
                    <AnimatedRoutes />
                    <ToastContainer />
                    <StatusWrapper />
                </App>
            </Router>
        </React.StrictMode>
    </Provider>
);

reportWebVitals();
