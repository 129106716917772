import React from "react";
import { useAppSelector } from "../../app/hooks";
import { RootState } from "../../app/store";
import './style.scss';

const StatusWrapper = () => {
    const { serverUnavailable, globalMsg } = useAppSelector(
        (state: RootState) => state.windowSlice
    );

    let msg = "";

    if (serverUnavailable) {
        msg = "Система временно недоступна. Попробуйте зайти позже.";
    } else if (globalMsg) {
        msg = globalMsg;
    }

    // Если ни serverUnavailable, ни globalMsg не заданы, блок не будет показан
    if (!serverUnavailable && !globalMsg) {
        return null;
    }

    return (
        <div className="status-wrapper">
            <span className="status-wrapper_text shown">{msg}</span>
        </div>
    );
};

export default StatusWrapper;