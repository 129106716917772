import React, { useState } from "react";
import { CSSTransition } from "react-transition-group";
import "./style.scss";
import { useAppSelector, useAppDispatch } from "../../app/hooks";
import { RootState } from "../../app/store";
import SubscriptionButton from "../../components/SubscriptionButton";
import { changeStep } from "../../redux/slices/windowSlice";
import Localized from "../../Localized";
import minusRedDark from "../../img/icons/dark/minus-red.svg";
import minusRedLight from "../../img/icons/light/minus-red.svg";
import linkPinkDark from "../../img/icons/dark/link-pink.svg";
import linkPinkLight from "../../img/icons/light/link-pink.svg";

import likeDark from "../../img/icons/dark/like-green.svg";
import likeLight from "../../img/icons/light/like.svg";
import Button from "../../components/Button";
import { initUtils } from "@telegram-apps/sdk";

const SubscriptionInfo = () => {
    const utils = initUtils();
    return (
        <div className="subscription-new">
            <div className="subscription-new_container">
                <div className="subscription-new_wrapper">
                    <div className="subscription-new_main-block">
                        <div className="subscription-new_main-block_information">
                            <div className="subscription-new_main-block_information_icon">
                                <img
                                    src=""
                                    alt=""
                                    className="subscription-new_main-block_information_icon_img"
                                />
                            </div>
                            <div className="subscription-new_main-block_information_details">
                                <span className="subscription-new_main-block_information_details_title">
                                    Терапия в лицах
                                </span>
                                <span className="subscription-new_main-block_information_details_plan">
                                    Тариф “8 марта”
                                </span>
                            </div>
                        </div>
                        <div className="subscription-new_main-block_plan-details">
                            <span className="subscription-new_main-block_plan-details_price">
                                12,99 USD / каждый месяц
                            </span>
                            <span className="subscription-new_main-block_plan-details_renew-information">
                                Следующее списание 6 апреля
                            </span>
                        </div>
                    </div>
                    <Button
                        title={Localized.GoToChat}
                        iconLight={linkPinkLight}
                        iconDark={linkPinkDark}
                        variant="pink"
                        action={() => {
                            utils.openTelegramLink('https://t.me/+fMAaK4K7pLFiMDc0');
                        }}
                    />
                    <Button
                        title="Посоветовать проект другу"
                        iconLight={likeLight}
                        iconDark={likeDark}
                        variant="AED885"
                        action={() => {
                            utils.shareURL('https://t.me/testqdenyawebbot', 'Рекомендую проект!');
                        }}
                    />
                    <Button
                        title={Localized.CancelSubscription}
                        iconLight={minusRedLight}
                        iconDark={minusRedDark}
                        variant="danger"
                    />
                </div>
            </div>
        </div>
    );
};

export default SubscriptionInfo;
