import { configureStore } from '@reduxjs/toolkit'
import { api } from '../redux/api';
import windowSlice from '../redux/slices/windowSlice';

export const store = configureStore({
  reducer: {
    windowSlice: windowSlice,
    
    [api.reducerPath]: api.reducer
  },
})

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch