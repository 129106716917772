import React, { useState } from "react";
import { CSSTransition } from "react-transition-group";
import "./style.scss";
import { useAppSelector, useAppDispatch } from "../../app/hooks";
import { RootState } from "../../app/store";
import SubscriptionButton from "../../components/SubscriptionButton";
import { changeStep } from "../../redux/slices/windowSlice";

import creditCardDark from "../../img/icons/dark/credit-card.svg";
import creditCardLight from "../../img/icons/light/credit-card.svg";

import linkPink from "../../img/icons/link-pink.svg";
import Button from "../../components/Button";
import SliderButton from "../../components/SliderButton";

const SubscriptionNew = () => {
    return (
        <div className="subscription-info">
            <div className="subscription-info_container">
                <div className="subscription-info_wrapper">
                    <div className="subscription-info_main-block">
                        <div className="subscription-info_main-block_information">
                            <div className="subscription-info_main-block_information_icon">
                                <img src="" alt="" className="subscription-info_main-block_information_icon_img" />
                            </div>
                            <div className="subscription-info_main-block_information_details">
                                <span className="subscription-info_main-block_information_details_title">Проект "Тестовый"</span>
                                <span className="subscription-info_main-block_information_details_plan">Тариф “Промо-ультра Инстаграм”</span>
                            </div>
                        </div>
                        <div className="subscription-info_main-block_plan-details">
                            <span className="subscription-info_main-block_plan-details_price">12,99 USD / каждый месяц</span>
                            <span className="subscription-info_main-block_plan-details_renew-information">Оплатите прямо сейчас и подписка будет активирована.</span>
                        </div>
                    </div>
                    <SliderButton />
                    <span className="subscription-info_text">или</span>
                    <Button title="Привязать новую карту" iconLight={creditCardLight} iconDark={creditCardDark} />
                </div>
            </div>
        </div>
    );
};

export default SubscriptionNew;
